<template>
  <div class="box">
    <i class="icon iconfont icon-kongzhuangtai"></i>
    <span class="b-empty">暂无数据</span>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      
    };
  },
  methods: {
    setSize() {
      let elMain = window.innerHeight
      document.querySelector('.box').style.height = elMain - 212 + 'px'
    },
  },
  mounted() {
    let that = this
    that.setSize()
    window.addEventListener('resize', function() {
      that.setSize()
    }, false);
  }
};
</script>
  
<style lang="less" scoped>
.box {
  overflow: auto;
  // background-color: #04102c;
  position: relative;
  // border-top-right-radius: 20px;
  // border-bottom-right-radius: 20px;
  height: 699px;
  color: #7d7d7d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  .icon {
    font-size: 200px;
  }
  .b-empty {
    margin-right: 50px;
    margin-top: 10px;
  }
}
</style>