<template>
    <div class="box-wrapper" v-loading="loading" 
      element-loading-spinner="el-icon-loading"
      element-loading-text="加载中"
      element-loading-background="rgba(0, 0, 0, 0.1)">
      <div class="content">
        <div class="box-title">
          <span>{{ datas.graphName }}</span>
          <div class="title-badge">
            <span class="badge" v-if="datas.statisticsDimension=='contrast'" :style="datas.statisticsDimension=='contrast'&&'background-color:#e74c3c;'">对比</span>
          </div>
        </div>
        <div id="distributionContrast1" class="distribution-contrast" :style="'height:'+height+'px;'"></div>
      </div>
    </div>
  </template>
  
  <script>
  import * as echarts from 'echarts';
  import dayjs from "dayjs"
  export default {
    props: {
      datas: {
        type: Object
      }
    },
    data() {
      return {
        loading: false,
        data: [],
        height: 0,
      };
    },
    watch: {
      datas: {
        handler (newVal, oldVal) {
          this.datas = newVal
          this.data = this.datas.data
          console.log(this.data)
          this.height = this.datas.data.length * 100
          let that = this
          that.setSize()
          window.addEventListener('resize', function() {
            that.setSize()
          }, false);
          newVal && newVal.length!=0 && this.setChart()
        }
      }
    },
    methods: {
      dayjs,
      setChart() {
        var myChart = echarts.init(document.getElementById("distributionContrast1"));
        var option = {
          title: {
            subtext: '-',
            top: -8,
            left: 18
          },
          backgroundColor: '',
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          legend: {
            show: true
          },
          // toolbox: {
          //   feature: {
          //     saveAsImage: {}
          //   }
          // },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: ['1:00', '2:00', '3:00', '4:00', '5:00', '6:00']
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: '上月',
              type: 'line',
              stack: 'Total',
              areaStyle: {
                opacity: 0.1
              },
              emphasis: {
                focus: 'series'
              },
              itemStyle: {
                color: '#5AF5EC',
                shadowColor: '#5AF5EC',
              },
              lineStyle: {
                shadowColor: '#5AF5EC',
                shadowBlur: 20,
                color: {
                  type: 'linear',
                  colorStops: [
                    {offset:0,color:'#5AF5EC'},
                    {offset:1,color:'#5AF5EC'},
                  ]
                }
              },
              smooth: true,
              data: [19,50,30,40,70,30]
            },
            {
              name: '本月',
              type: 'line',
              stack: 'Total',
              areaStyle: {
                opacity: 0.1
              },
              emphasis: {
                focus: 'series'
              },
              itemStyle: {
                color: '#FFC332',
                shadowColor: '#FFC332',
              },
              lineStyle: {
                shadowColor: '#FFC332',
                shadowBlur: 20,
                color: {
                  type: 'linear',
                  colorStops: [
                    {offset:0,color:'#FFC332'},
                    {offset:1,color:'#FFC332'},
                  ]
                }
              },
              smooth: true,
              data: [19,81,24,26,52,47]
            }
          ],
        };
        // let xAxisData = []
        // this.data.dataTrends.forEach(v => {
        //   xAxisData.push(v.date)
        // })
        // let x = []
        // for(var i = 0; i < xAxisData.length; i++){
        //   if(x.indexOf(xAxisData[i]) == -1){
        //     x.push(xAxisData[i]);
        //   }
        // }
        // x.sort()
        // option.xAxis.data = x;
  
        // this.data.dataTrends.forEach(v => {
        //   option.series[0].data.push(v.value)
        // })
        
        myChart.setOption(option);
        setTimeout(()=>{
          window.onresize = function () {
            myChart.resize();
          }
          window.addEventListener('keydown', function(event) {
            if(event.keyCode===122) {
              myChart.resize();
            }
          })
        },0)
      },
      setSize() {
        let elMain = window.innerHeight
        let rBox = document.querySelector('.box-wrapper')
        let rMain = document.querySelector('.distribution-contrast')
        if(rBox) rBox.style.height = elMain - 212 + 'px'
        if(rMain) rMain.style.height = this.height + 'px'
      },
    },
    mounted() {
      this.data = this.datas.data
      console.log(this.data)
      this.setChart()
      let that = this
      that.setSize()
      window.addEventListener('resize', function() {
        that.setSize()
      }, false);
    }
  };
  </script>
  
  <style lang="less" scoped>
  .distribution-contrast {
    width: 100%;
    height: 200px;
    margin: 20px auto 0;
    z-index: 2;
  }
  .box-title {
    color: #FFF;
    font-size: 20px;
    padding: 0 16px;
    height: 50px;
    line-height: 50px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: 25px;
    left: 40px;
    z-index: 1;
  }
  /deep/.el-loading-spinner .el-icon-loading{
    font-size: 40px;
  }
  .box-wrapper {
    overflow: hidden;
    background-color: #04102c;
    position: relative;
    border-radius: 10px;
    // height: 831px;
    .chart-title {
      width: 93%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 28px auto 0;
      z-index: 0;
    }
    .chart-bg {
      width: 98%;
      height: 95%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      z-index: 0;
    }
    .content {
      border-radius: 10px;
      padding-bottom: 16px;
    }
    .date {
      display: flex;
      position: absolute;
      right: 10%;
      top: 0;
      z-index: 10;
      .date-text {
        font-size: 12px;
        font-weight: normal;
        color: #FFF;//#767779
        margin-right: 10px;
        user-select: none;
      }
    }
  }
  .el-form-item {
    margin-bottom: 0;
  }
  /deep/.el-form-item__content {
    line-height: 50px;
  }
  /deep/.el-form-item__label {
    font-size: 12px;
    font-weight: normal;
    color: #FFF;//#767779
  }
  </style>