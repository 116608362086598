<template>
    <div class="box-wrapper" v-loading="loading" 
      element-loading-spinner="el-icon-loading"
      element-loading-text="加载中"
      element-loading-background="rgba(0, 0, 0, 0.1)">
      <div class="content">
        <div class="box-title">
          <span>{{ datas.graphName }}</span>
          <div class="title-badge">
            <span class="badge" v-if="datas.statisticsDimension=='trend'" :style="datas.statisticsDimension=='trend'&&'background-color:#f39c12;'">
              {{ datas.statisticsType=='daily'?'单日趋势':
              datas.statisticsType=='weekly'?'7日趋势':
              datas.statisticsType=='monthly'?'30日趋势':
              datas.statisticsType=='oneYear'?'近1年趋势':
              datas.statisticsType=='secYear'?'近2年趋势':'趋势' }} 
            </span>
          </div>
        </div>
        <div id="distributiontotal1" class="distribution-total" :style="'height:'+height+'px;'"></div>
      </div>
    </div>
  </template>
  
  <script>
  import * as echarts from 'echarts';
  import dayjs from "dayjs"
  // 获取当前时间
  const currentDate = new Date();
  // 获取昨天的日期
  const yesterday = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000);
  // 设置昨天的时间为0点
  yesterday.setHours(0, 0, 0, 0);
  // 获取昨天0点的时间戳
  const yesterdayTimestamp = yesterday.getTime();
  // 获取昨天24点的时间戳
  const tomorrowTimestamp = yesterdayTimestamp + 24 * 60 * 60 * 1000;
  var colorList = [
    '#5AF5EC','#9FE080','#FFDC60','#FF915A','#839be2',
    '#e8927d','#9BCA63','#fbe183','#F3A43B','#7ED3F4',
    '#D7504B','#C6E579','#F4E001','#F0805A','#6ae2e2',
    '#FFB7DD','#f099ff','#FFCCCC','#FFC8B4','#cf80ff',
    '#FFFFBB','#FFAA33','#99FFFF','#CC00CC','#FF77FF',
    '#CC00CC','#C63300','#F4E001','#9955FF','#66FF66',
    
    '#5AF5EC','#9FE080','#fbe183','#FF915A','#839be2',
    '#e8927d','#9BCA63','#FAD860','#F3A43B','#7ED3F4',
    '#D7504B','#C6E579','#F4E001','#F0805A','#6ae2e2',
    '#FFB7DD','#f099ff','#FFCCCC','#FFC8B4','#cf80ff',
    '#FFFFBB','#FFAA33','#99FFFF','#CC00CC','#FF77FF',
    '#CC00CC','#C63300','#F4E001','#9955FF','#66FF66'
  ];
  export default {
    props: {
      datas: {
        type: Object
      }
    },
    data() {
      return {
        loading: false,
        data: [],
        height: 600,
        formInline: {
          cate: 'last_seven_day'
        },
        categorys: [
          { code: 'last_seven_day', name: '最近7天' },
          { code: 'last_fifteen_day', name: '最近15天' },
          // { code: 'last_month', name: '月' },
          // { code: 'last_quarter', name: '季' },
          // { code: 'last_year', name: '年' },
        ],
        pickerOptions: {
          disabledDate: time => {
            let d = dayjs(time).format("YYYYMMDD").valueOf();
            let n = dayjs().format("YYYYMMDD").valueOf();
            return d >= n;
          }
        },
        stepTime: null,
        currentTime: new Date(),
        intervalTime: 0,
        reportData: [],
        graphData: [],
        myChart: null,
        weeklyDate: [],
        monthlyDate: [],
        secYearDate: []
      };
    },
    watch: {
      datas: {
        handler (newVal, oldVal) {
          this.datas = newVal
          this.data = this.datas.data
          // this.height = this.datas.data.length * 100
          let that = this
          that.setSize()
          window.addEventListener('resize', function() {
            that.setSize()
          }, false);
          newVal && newVal.length!=0 && this.setChart()
        }
      }
    },
    methods: {
      dayjs,
      // 封装指定时间格式
      getCurrentTime(time) {
        let date = new Date(time)
        let year = date.getFullYear()
        let month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
        let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
        let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
        let minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
        let second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`
      },
      setChart() {
        this.myChart = echarts.init(document.getElementById("distributiontotal1"));
        var option = {
          title: {
            subtext: '-',
            top: -8,
            left: 18
          },
          backgroundColor: '',
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          // toolbox: {
          //   feature: {
          //     saveAsImage: {}
          //   }
          // },
          grid: {
            top: '20%',
            left: '3%',
            right: '4%',
            bottom: '1%',
            containLabel: true
          },
          xAxis: [],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: this.graphData,
          legend: {
            width: '80%',
            y: 'top',
            data: [],
            selected: {
              '全选': true,
            }
          }
        };
        option.title.subtext = '单位: '+(this.datas?this.datas.unitAttribute:'')
        this.data.forEach((v,i)=>{
          option.legend.data.push(v.equipmentName+'['+v.tag+'-'+v.describe+']')
          let intervalTime = Number(((tomorrowTimestamp - yesterdayTimestamp) / v.values.length).toFixed(0))
          let obj = {
            name: v.equipmentName+'['+v.tag+'-'+v.describe+']',
            type: 'line',
            // areaStyle: {
            //   opacity: 0.1
            // },
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              color: colorList[i],
              shadowColor: colorList[i],
            },
            lineStyle: {
              // shadowColor: colorList[i],
              // shadowBlur: 20,
              color: {
                type: 'linear',
                colorStops: [
                  {offset:0,color:colorList[i]},
                  {offset:1,color:colorList[i]},
                ]
              }
            },
            // smooth: true,
            showSymbol: false,
            data: []
          }
          let resArr
          if(this.datas.statisticsType=='daily') resArr = this.dailyTrend(v.values,intervalTime)
          if(this.datas.statisticsType=='weekly') resArr = this.weeklyTrend(v.values)
          if(this.datas.statisticsType=='monthly') resArr = this.monthlyTrend(v.values)
          if(this.datas.statisticsType=='secYear') resArr = this.secYearTrend(v.values)
          obj.data = resArr
          this.graphData.push(obj)
        })
        // 单日x轴样式
        if(this.datas.statisticsType=='daily') {
          option.xAxis = [
            {
              type: 'time',
              plitLine: {
                show: false
              },
              splitNumber:8,
              axisLine:{
                lineStyle:{
                  color: '#1B2232'
                }
              },
              min: this.getCurrentTime(yesterdayTimestamp),
              max: this.getCurrentTime(tomorrowTimestamp),
            }
          ]
        }
        // 7日x轴样式
        if(this.datas.statisticsType=='weekly') {
          option.xAxis = [
            {
              type: "category",
              boundaryGap: false,
              data: this.weeklyDate
            }
          ]
        }
        // 30日x轴样式
        if(this.datas.statisticsType=='monthly') {
          option.xAxis = [
            {
              type: "category",
              boundaryGap: false,
              data: this.monthlyDate
            }
          ]
        }
        // 近2年x轴样式
        if(this.datas.statisticsType=='secYear') {
          option.xAxis = [
            {
              type: "category",
              boundaryGap: false,
              data: this.secYearDate
            }
          ]
        }
        // console.log(this.graphData)
        option.legend.data.push('全选')
        this.graphData.push({
          name: '全选',
          type: 'line',
          data: [],
        })
        option.series = this.graphData
        this.myChart.setOption(option);
        setTimeout(()=>{
          this.myChart.on('legendselectchanged', (params) => {
            if(params.name == '全选') {
              var selectMark = option.legend.selected['全选']
              var data = option.legend.data
              if(selectMark) {
                for (var i=0;i<data.length;i++) {
                  option.legend.selected[data[i]] = false
                }
              }else {
                for (var i=0;i<data.length;i++) {
                  option.legend.selected[data[i]] = true
                }
              }
              this.myChart.setOption(option)
            }
          })
          window.onresize = function () {
            this.myChart.resize();
          }
          window.addEventListener('keydown', function(event) {
            if(event.keyCode===122) {
              this.myChart.resize();
            }
          })
        },0)
      },
      // 单日趋势数据
      dailyTrend(values,intervalTime) {
        let arr = []
        let t
        let stepTime = yesterdayTimestamp
        if(values.length>0) {
          values.forEach(item=> {
            if(parseFloat(item.value)<0) item.value = 0
            if(stepTime<=tomorrowTimestamp) {
              t = new Date(stepTime).getTime()
              arr.push({
                name: t,
                value: [t,parseFloat(item.value).toFixed(2)]              
              })
              stepTime += intervalTime
            }
          })
          return arr
        }
      },
      // 7日趋势数据
      weeklyTrend(values) {
        let arr = []
        let date = []
        if(values.length>0) {
          values.forEach(item=> {
            if(parseFloat(item.value)<0) item.value = 0
            arr.push({
              name: item.date,
              value: [item.date,parseFloat(item.value).toFixed(2)]              
            })
            date.push(item.date)
          })
          this.weeklyDate = date
          return arr
        }
      },
      // 30日趋势数据
      monthlyTrend(values) {
        let arr = []
        let date = []
        if(values.length>0) {
          values.forEach(item=> {
            if(parseFloat(item.value)<0) item.value = 0
            arr.push({
              name: item.date,
              value: [item.date,parseFloat(item.value).toFixed(2)]              
            })
            date.push(item.date)
          })
          this.monthlyDate = date
          return arr
        }
      },
      // 近2年趋势数据
      secYearTrend(values) {
        let arr = []
        let date = []
        if(values.length>0) {
          values.forEach(item=> {
            if(parseFloat(item.value)<0) item.value = 0
            arr.push({
              name: item.date,
              value: [item.date,parseFloat(item.value).toFixed(2)]              
            })
            date.push(item.date)
          })
          this.secYearDate = date
          return arr
        }
      },
      setSize() {
        let elMain = window.innerHeight
        let rBox = document.querySelector('.box-wrapper')
        let rMain = document.querySelector('.distribution-total')
        if(rBox) rBox.style.height = elMain - 212 + 'px'
        if(rMain) rMain.style.height = this.height + 'px'
      },
    },
    created() {
      // this.height = this.datas.data.length * 100
    },
    mounted() {
      this.data = this.datas.data
      this.setChart()
      let that = this
      that.setSize()
      window.addEventListener('resize', function() {
        that.setSize()
      }, false);
    }
  };
  </script>
  
  <style lang="less" scoped>
  .distribution-total {
    width: 100%;
    height: 770px;
    margin: 20px auto 0;
    z-index: 2;
  }
  .box-title {
    // color: #FFF;
    font-size: 18px;
    padding: 0 16px;
    height: 50px;
    line-height: 50px;
    font-weight: bold;
    display: flex;
    align-items: center;
    position: relative;
    top: 6px;
    left: 6px;
    z-index: 1;
    .title-badge {
      margin-left: 20px;
      .badge {
        background: #409EFF;
        color: #FFF;
        padding: 2px 10px;
        border-radius: 4px;
        font-size: 14px;
        line-height: 50px;
        font-weight: normal;
      }
    }
  }
  /deep/.el-loading-spinner .el-icon-loading{
    font-size: 40px;
  }
  .box-wrapper {
    overflow: hidden;
    // background-color: #04102c;
    position: relative;
    // border-radius: 10px;
    // height: 831px;
    // margin-bottom: 10px;
    .chart-title {
      width: 93%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 18px auto 0;
      z-index: 0;
    }
    .chart-bg {
      width: 98%;
      height: 95%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      z-index: 0;
    }
    .content {
      border-radius: 10px;
      padding-bottom: 16px;
    }
    .date {
      display: flex;
      position: absolute;
      right: 10%;
      top: 0;
      z-index: 10;
      .date-text {
        font-size: 12px;
        font-weight: normal;
        color: #FFF;//#767779
        margin-right: 10px;
        user-select: none;
      }
    }
  }
  .el-form-item {
    margin-bottom: 0;
  }
  /deep/.el-form-item__content {
    line-height: 50px;
  }
  /deep/.el-form-item__label {
    font-size: 12px;
    font-weight: normal;
    color: #FFF;//#767779
  }
  </style>