<template>
    <div class="box-wrapper" v-loading="loading" 
      element-loading-spinner="el-icon-loading"
      element-loading-text="加载中"
      element-loading-background="rgba(0, 0, 0, 0.1)">
      <div class="content">
        <div class="box-title">
          <span>{{ datas.graphName }}</span>
          <div class="title-badge">
            <span class="badge" v-if="datas.statisticsDimension=='contrast'" :style="datas.statisticsDimension=='contrast'&&'background-color:#e74c3c;'">对比</span>
          </div>
        </div>
        <div id="barContrast2" class="bar-contrast" :style="'height:'+height+'px;'"></div>
      </div>
    </div>
  </template>
  
  <script>
  import * as echarts from 'echarts';
  import dayjs from 'dayjs'
  
  export default {
    props: {
      datas: {
        type: Object
      }
    },
    data() {
      return {
        loading: false,
        data: [],
        height: 0,
      };
    },
    watch: {
      datas: {
        handler (newVal, oldVal) {
          this.datas = newVal
          this.data = this.datas.data
          console.log(this.data)
          this.height = this.datas.data.length * 100
          let that = this
          that.setSize()
          window.addEventListener('resize', function() {
            that.setSize()
          }, false);
          newVal && newVal.length!=0 && this.setChart()
        }
      }
    },
    methods: {
      dayjs,
      setChart() {
        var myChart = echarts.init(document.getElementById("barContrast2"));
        var option = {
          title: {
            subtext: '-',
            top: -8,
            left: 18
          },
          backgroundColor: '',
          color: ['#60EEFF'],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
              label: {
                backgroundColor: '#6a7985'
              }
            },
            // formatter:function(params) {
            //   return params[0].name +'<br>'+params[0].seriesName+': '+params[0].data+'('+option.title.subtext+')'
            // },
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            // axisLabel: {
            //   interval:0,
            //   rotate:40
            // },
            axisTick: {
              show: false
            },
            data: []
          },
          yAxis: {
            type: 'value',
            boundaryGap: [0, 0.01],
            axisTick: {
              show: false
            },
          },
          series: [
            {
              name: '本月',
              type: 'bar',
              data: [20,40,60,100,70],
              barWidth: 12,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0,0,0,1,[{
                  offset: 0,
                  color: '#31F398'
                },{
                  offset: 1,
                  color: '#11C245'
                }])
              },
            },
            {
              name: '上月',
              type: 'bar',
              data: [20,40,60,100,70],
              barWidth: 12,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0,0,0,1,[{
                  offset: 0,
                  color: '#63FCEB'
                },{
                  offset: 1,
                  color: '#05AAF4'
                }])
              },
            }
          ],
          legend: {
            show: true
          },
        };
        option.yAxis.data = []
        option.title.subtext = '单位: '+(this.datas?this.datas.unitAttribute:'')
        option.series[0].name = this.datas?this.datas.elemental1Attribute:''
        this.data.forEach(item => {
          option.yAxis.data.push(item.equipmentName)
          option.series[0].data.push(item.value?parseFloat(parseFloat(item.value).toFixed(2)):0)
        });
        myChart.setOption(option);
        setTimeout(()=>{
          window.onresize = function () {
            myChart.resize();
          }
          window.addEventListener('keydown', function(event) {
            if(event.keyCode===122) {
              myChart.resize();
            }
          })
        },0)
      },
      chartsSize(container, charts) {
        function getStyle(el, name) {
          if(window.getComputedStyle) {
            return window.getComputedStyle(el, null);
          }else {
            return el.currentStyle;
          }
        }
        let hi = getStyle(container, 'height').height;
        charts.style.height = hi;
      },
      setSize() {
        let elMain = window.innerHeight
        let rBox = document.querySelector('.box-wrapper')
        let rMain = document.querySelector('.bar-contrast')
        if(rBox) rBox.style.height = elMain - 212 + 'px'
        if(rMain) rMain.style.height = this.height + 'px'
      },
    },
    mounted() {
      this.data = this.datas.data
      console.log(this.data)
      this.setChart()
      let that = this
      that.setSize()
      window.addEventListener('resize', function() {
        that.setSize()
      }, false);
    }
  };
  </script>
  
  <style lang="less" scoped>
  .bar-contrast {
    width: 100%;
    height: 200px;
    margin: 20px auto 0;
    z-index: 2;
  }
  .box-title {
    color: #FFF;
    font-size: 20px;
    padding: 0 16px;
    height: 50px;
    line-height: 50px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 25px;
    left: 40px;
    z-index: 1;
  }
  /deep/.el-loading-spinner .el-icon-loading{
    font-size: 40px;
  }
  .box-wrapper {
    overflow: hidden;
    background-color: #04102c;
    position: relative;
    border-radius: 10px;
    .chart-title {
      width: 93%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 28px auto 0;
      z-index: 0;
    }
    .chart-bg {
      width: 98%;
      height: 95%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      z-index: 0;
    }
    .content {
      border-radius: 10px;
      padding-bottom: 16px;
      .search {
        overflow: hidden;
      }
      .box-content {
        padding-bottom: 10px;
        >div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px;
          height: 78px;
          color: #333;
          box-sizing: border-box;
        }
        .imgs {
          width: 48px;
          height: 48px;
          background: rgb(71, 154, 202);
          border-radius: 5px;
          text-align: center;
          line-height: 48px;
          color: #FFF;
          font-size: 24px;
          overflow: hidden;
          position: relative;
        }
        .imgs:after {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          pointer-events: none;
          background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
          background-repeat: no-repeat;
          background-position: 50%;
          transform: scale(10, 10);
          opacity: 0;
          transition: transform 0.3s, opacity 0.5s;
        }
        .imgs:active:after {
          transform: scale(0, 0);
          opacity: 0.6;
          transition: 0s;
        }
        .bg-colors1 { background-color: #FFDC60; }
        .bg-colors2 { background-color: #7ED3F4; }
        .bg-colors3 { background-color: #FF915A; }
        .bg-colors4 { background-color: #9FE080; }
        .bg-colors5 { background-color: #FF7070; }
        .bg-colors6 { background-color: #5C7BD9; }
        .title {
          font-size: 14px;
          color: #767779;
        }
        .data {
          font-size: 20px;
          margin-top: 8px;
          color: #202020;
        }
      }
    }
    .date {
      display: flex;
      align-items: center;
      .date-text {
        font-size: 12px;
        font-weight: normal;
        color: #FFF;//#767779
        margin-right: 10px;
        user-select: none;
      }
    }
  }
  </style>